$( document ).ready(function() { 
    var getIEVersion = function() {
      var sAgent = window.navigator.userAgent;
      var Idx = sAgent.indexOf("MSIE");

      // If IE, return version number.
      if (Idx > 0) { 
        return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));

      // If IE 11 then look for Updated user agent string.
      } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
        return 11;

      } else {
        return 0; //It is not IE
      }
    };

    var ie_version = getIEVersion();
    if (ie_version >= 9 && ie_version <= 11) {
        console.log("IE:", getIEVersion());
        window.setImmediate = window.setImmediate.bind(window);
        console.log('immediate fixes');
    }
});
